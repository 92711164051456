<template>
  <div v-if="fetchingTrans" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>
    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <div
            v-if="newInvestment"
            :class="[newInvestment ? 'w3-block' : '']"
            class="w3-modal z-index-0"
          >
            <div class="w3-modal-content w3-animate-top relative">
              <a
                href="javascript:;"
                @click.prevent="newInvestment = false"
                style="top: -10px"
                class="w3-button w3-orange hover-effect-tiny w3-border no-hover-underline btn-rounded absolute right-10"
              >
                x</a
              >
              <NewMIInvestment
                callback="mi"
                :fullname="Fullname"
                :email="Email"
                :contactno="ContactNo"
                :phoneno="PhoneNo"
                @finished="newInvestment = false"
              />
            </div>
          </div>
          <div class="row equalHeightColumns pb-3 mb-4 w3-border-bottom">
            <template v-if="MIRunningBal">
              <div v-if="fetching" class="col-xl-4 col-md-6">
                <img class="mr-3" src="base/img/loading.gif" width="40" />
                Loading packages
              </div>
              <template v-else>
                <MIInvestmentSummary
                  :amount="FundAmounts"
                  :length="MIRunningBal.length"
                  @mounted="equalHeightColumns"
                />
              </template>
            </template>
            <div v-if="!newInvestment" class="col-xl-4 col-md-6">
              <NewInvestmentButton
                @click="newInvestment = true"
                :label="'ADD A NEW PORTFOLIO'"
              />
            </div>
          </div>
          <MIInvestmentsCard referral="mi" />
          <div class="p-4 w3-white">
            <MITransactions v-if="mounted" />
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</template>
<script>
import MIInvestmentSummary from "@/components/MIInvestmentSummary";
import NewInvestmentButton from "@/components/NewInvestmentButton";
import MITransactions from "@/components/MITransactions";
import MIInvestmentsCard from "@/components/MIInvestmentsCard";
import {
  formatter,
  equalHeightColumns,
  queryParameter,
} from "@/helpers/utilities";
import usePayments from "@/composables/use-payments";
import { ref, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import NewMIInvestment from "@/components/NewMIInvestment.vue";

export default {
  components: {
    MIInvestmentSummary,
    NewInvestmentButton,
    MIInvestmentsCard,
    MITransactions,
    NewMIInvestment,
  },
  setup() {
    const store = useStore();
    const MIRunningBal = computed(() => store.state.MIRunningBal);
    const fetching = computed(() => store.state.fetching.MIRunningBal);
    const Fullname = computed(() => store.state.userData.Account_Name);
    const Email = computed(() => store.state.userData.EMail);
    const PhoneNo = computed(() => store.state.userData.PhoneNo);
    const ContactNo = computed(() => store.state.userData.contactNo);
    if (!MIRunningBal.value?.length) {
      store.dispatch("MIRunningBal");
    }

    const newInvestment = ref(false);
    const format = (num) => formatter.format(num);
    const mounted = ref(false);
    const FundAmounts = computed(() => {
      const totalAmount = {
        totalInvested: 0,
        totalValueToday: 0,
        totalExpected: 0,
        count: 0,
      };
      for (let inv in MIRunningBal.value) {
        totalAmount.totalInvested += parseFloat(
          MIRunningBal.value[inv].AmountInvested
        );
        totalAmount.totalValueToday += parseFloat(
          MIRunningBal.value[inv].InvestmentValueAtToday
        );
        totalAmount.totalExpected += parseFloat(
          MIRunningBal.value[inv].ExpectedValueAtMaturity
        );
        totalAmount.count++;
      }
      return totalAmount;
    });

    const title = ref("MI Investments");
    onMounted(() => {
      mounted.value = true;
      equalHeightColumns();
    });

    const retry = ref(1);
    const PaymentSuccess = (data) => {
      if (data.Trx.apiCalled !== 1) {
        if (retry.value <= 3) {
          retry.value++;
          Callback(trxref, retry.value);
        }
      }
    };
    const { IframeCallback: Callback, fetching: fetchingTrans } = usePayments(
      PaymentSuccess
    );
    const trxref = queryParameter().trxref;
    onBeforeMount(() => {
      if (trxref) {
        Callback(trxref, retry.value);
      }

      store.dispatch("resetIdleTime");
    });

    return {
      title,
      MIRunningBal,
      fetching,
      fetchingTrans,
      FundAmounts,
      format,
      newInvestment,
      mounted,
      Fullname,
      Email,
      ContactNo,
      PhoneNo,
      equalHeightColumns,
    };
  },
};
</script>
